import type { SwiperOptions } from "swiper/types";

export const swiperOptions: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 24,

    breakpoints: {
        464: {
            slidesPerView: 1,
        },
        744: {
            slidesPerView: 1,
        },
        984: {
            slidesPerView: 1,
        },
        1200: {
            slidesPerView: 1,
        },
        1440: {
            slidesPerView: 1,
        },
        1700: {
            slidesPerView: 1,
        },
    },
};
