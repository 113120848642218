<script setup lang="ts">
/**
 * @author Ia Gugunava
 */
//@ts-nocheck
import { ref, Ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useMainStore } from "@/store/main/index";
import { swiperOptions } from "./ts/swiper";
import { ImageInterface } from "@/types/ContentType";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/vue";

import ArrowLeft from "@/components/regularComponents/UIComponents/SVG/actions/ArrowLeft.vue";
import ArrowRight from "@/components/regularComponents/UIComponents/SVG/actions/ArrowRight.vue";
import X from "@/components/regularComponents/UIComponents/SVG/actions/X.vue";
const Picture = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Picture.vue")
);

// import liveCamera from "./parts/live.html?raw";

import HLSCore from "@cloudgeek/playcore-hls";

const player = ref(null);
const liveCamera = ref();

const props = defineProps<{
  data: ImageInterface[] | string | undefined;
  currentImages: Number;
  imgTitle?: string;
  isOpen?: boolean;
  isVideo?: boolean;
  videoLink?: string;
  imageStyle?: boolean;
}>();

const exitClass: Ref<boolean> = ref(false);
const openClass: Ref<boolean> = ref(false);
// const currentImage: Ref<object | null> = ref(null);
const duration: number = 600;
const currentIndex: Ref<number> = ref(0);
// const changeClass: Ref<boolean> = ref(false);
// const openModalClass: Ref<boolean> = ref(true);
const isOpening: Ref<boolean> = ref(false);
const swiperInstance = ref(null);
const images: Ref<HTMLImageElement[]> = ref([]);
const imageHeights: Ref<number[]> = ref([]);
const imageWidths: Ref<number[]> = ref([]);
const galleryKey: Ref<number> = ref(0);
const isLoop: Ref<boolean> = ref(true);
// let switchBreak: boolean = false;

const imagesData = computed(
  () => props?.data?.filter((item) => item?.devices) || props?.data
);

// const loopie = computed(() => {
//   if (imagesData.value.length === 1) {
//     isLoop.value = false;
//   }
// });

const typeChecker = typeof props?.data == "object";

const mainStore = useMainStore();

const emit = defineEmits(["close"]);

const isDesktop = computed(() => mainStore.windowWidth >= mainStore.breakpoints.tablet);

const getSwiperInstance = (sw: any) => {
  swiperInstance.value = sw;
};

const closeModal = (): void => {
  openClass.value = false;
  window.setTimeout(() => {
    exitClass.value = true;
  }, 0);
  window.setTimeout(() => {
    emit("close", false);
  }, 0);
};

const onKeyUp = (e: KeyboardEvent) => {
  const isLeft = e.code === "ArrowLeft";
  const isRight = e.code === "ArrowRight";
  const isEsc = e.code === "Escape";

  if (isEsc) {
    closeModal();
  }
  if (isLeft) {
    swiperInstance.value.slidePrev();
  }
  if (isRight) {
    swiperInstance.value.slideNext();
  }
};

const refreshGallery = () => {
  galleryKey.value++;

  if (images.value.length) {
    imageHeights.value = Array.from(images.value).map(
      (x) => x?.getBoundingClientRect().height
    );
    imageWidths.value = Array.from(images.value).map(
      (x) => x?.getBoundingClientRect().width
    );
  }
};

onMounted(() => {
  refreshGallery();

  document?.addEventListener("keyup", onKeyUp);

  window?.addEventListener("resize", refreshGallery);

  isOpening.value = true;
  setTimeout(() => {
    isOpening.value = false;
  }, duration);

  if (typeof props.data === "object" && props?.data?.filter(Boolean).length === 1) {
    isLoop.value = false;
  }
});

onBeforeUnmount(() => {
  document.removeEventListener("keyup", onKeyUp);

  window.removeEventListener("resize", refreshGallery);
});
</script>

<template>
  <div
    class="gallery d-flex al-center j-center"
    :class="[{ exit: exitClass, openClass, isOpening }, { 'second-image': imageStyle }]"
    v-if="isOpen"
  >
  {{ lorem10   }}
    <div class="gallery__close pos-abs pointer" @click.stop="closeModal">
      <X />
    </div>
    <div
      class="gallery__switch--desktop"
      v-if="typeChecker && isLoop && imagesData?.length > 1"
    >
      <button
        @click.stop="swiperInstance.slidePrev()"
        class="gallery__switch__left-arrow pointer"
      >
        <ArrowLeft />
      </button>
      <button
        @click.stop="swiperInstance.slideNext()"
        class="gallery__switch__right-arrow pointer"
      >
        <ArrowRight />
      </button>
    </div>

    <div
      class="gallery__wrapper d-flex pos-rel"
      :class="{ 'gallery__wrapper--live': videoLink }"
      :style="{
        height: isDesktop
          ? ''
          : imageHeights[currentIndex] < 100
          ? '420px'
          : imageHeights[currentIndex] + 60 + 'px',
        width: isDesktop
          ? ''
          : imageWidths[currentIndex] < 100
          ? '420px'
          : imageWidths[currentIndex] + 60 + 'px',
      }"
      :key="galleryKey"
    >
      <div class="gallery__wrapper__swiper" v-if="typeChecker">
        <Swiper
          v-click-outside="closeModal"
          @swiper="getSwiperInstance"
          ref="carouselRef"
          @keyPress="test"
          :initialSlide="props.currentImages"
          :slides-per-view="swiperOptions.slidesPerView"
          :space-between="swiperOptions.spaceBetween"
          :breakpoints="swiperOptions.breakpoints"
          :options="swiperOptions"
          :grabCursor="true"
          :loop="isLoop"
        >
          <SwiperSlide v-for="(item, index) in imagesData" :key="index">
            <div class="cover">
              <Picture :devices="item?.devices" :alt="imgTitle" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div class="gallery__wrapper--image" v-else-if="!isVideo">
        <img :src="props.data" v-click-outside="closeModal" alt="image" loading="lazy" />
      </div>

      <div class="gallery__wrapper--video" v-else>
     <ClientOnly>
        <!-- <vue3-video-player :core="HLSCore" :src="videoLink" title="" autoplay>
        </vue3-video-player> -->
     </ClientOnly>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.play-pause-layer .btn-control .btn-play {
  path {
    fill: $mango;
  }
}
.vcp-dashboard {
  display: none !important;
}
.gallery {
  z-index: 8;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.96);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 34px;

  img {
    width: 100%;
    height: 100%;
  }

  &__close {
    z-index: 3;
    padding: 7px;
    top: 48px;
    right: 48px;
    border: 1px solid rgba($white, 0.2);
    @include easeInOut(400, all);

    .icon {
      width: 48px;
      height: 48px;
      background: $white;

      svg {
        path {
          stroke: $border-active;
        }
      }
      @include maxq(desktop) {
        width: 32px;
        height: 32px;
      }
    }

    @include hover {
      padding: 12px;
      top: 43px;
      right: 43px;
    }
  }

  &__switch {
    &--desktop {
      padding: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: calc(100% - 42px);
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        position: relative;
        z-index: 2;
      }
    }

    &__left-arrow {
      padding: 30px 0 30px 30px;
      @include easeInOut(400, all);
      @include hover {
        transform: translateX(-8px);
      }
    }

    &__right-arrow {
      padding: 30px 0 30px 30px;
      @include easeInOut(400, all);

      @include hover {
        transform: translateX(8px);
      }
    }

    &--mobile {
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: calc(100% - 42px);
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg {
        position: relative;
        z-index: 2;
      }
    }
  }

  @include maxq(tablet) {
    padding: 11px;
    justify-content: center;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: 100%;
    object-fit: contain;
    z-index: 4;
    border: 2px solid $white;
    padding: 32px;
    @include easeInOut(400, all);

    // @include maxq(mobile){
    //   max-height: 100%;
    // }
  }

  &__wrapper {
    flex-direction: column;
    z-index: 0;
    width: 74.3%;
    height: 100%;
    overflow: hidden;

    &--live {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__swiper {
      height: 100%;
      .swiper {
        height: 100%;
      }
      // @include maxq(mobile){
      //   height: calc(100% / (480 / 322));
      // }

      // @media (max-width: 567px){
      //   height: 50%;
      // }
    }
    &--video {
      video {
        border: 2px solid $white;
        padding: 32px;
      }
    }
    @include maxq(mobile) {
      display: flex;
      justify-content: center;
    }
    // @include maxq(tablet){
    //   height: unset;
    // }
  }
  &.second-image {
    img {
      @include maxq(desktop-sm) {
        min-height: unset;
        object-fit: unset;
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
